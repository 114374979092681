///import { loadJSON } from "../helpers/loadJSON";

const config = "";  //loadJSON('/config.json');

export function getBackendURL() {
  //return "http://localhost:8080/";
 return "https://srv1back.crmstation.net";
}

export function getBackendSocketURL() {
  //return "http://localhost:8080/";
 return "https://srv1back.crmstation.net";
}

export default config;
